<template>
  <div>
    <div class="sub-nav">
      <div class="container">
        <div class="sub-nav-list">
          <span class="nav-title">My Company</span>
          <div class="vline"></div>
          <div class="sub-nav-group">
            <a
              class="sub-nav-item"
              :class="{ active: selectedComponent === 'info' }"
              @click="
                (selectedComponent = 'info'),
                  $router.push({ query: { type: 'info' } })
              "
            >
              Company Info
            </a>
            <a
              class="sub-nav-item"
              :class="{ active: selectedComponent === 'emailSetting' }"
              @click="
                (selectedComponent = 'emailSetting'),
                  $router.push({ query: { type: 'emailSetting' } })
              "
            >
              Email Setting
            </a>
            <a
              class="sub-nav-item"
              :class="{ active: selectedComponent === 'users' }"
              @click="
                (selectedComponent = 'users'),
                  $router.push({ query: { type: 'users' } })
              "
            >
              User
            </a>
          </div>
        </div>
      </div>
    </div>

    <component
      :is="selectedComponent"
      v-if="Company"
      :company="Company"
    ></component>
  </div>
</template>

<script>
import info from "@coms/mycompany/info.vue";
import emailSetting from "@coms/mycompany/email-setting.vue";
import users from "@coms/mycompany/users.vue";
import { getCompanyPofile } from "@/apis/company-api";
export default {
  components: {
    info,
    emailSetting,
    users,
  },
  data: () => ({
    selectedComponent: "",
    Company: null,
  }),
  created() {
    this.getCompanyPofile();
    if (!this.$route.query.type) {
      this.$router.push({ query: { type: "info" } });
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.selectedComponent = this.$route.query.type;
      },
      immediate: true,
    },
  },
  methods: {
    async getCompanyPofile() {
      const company_Profile = await getCompanyPofile()
      this.Company = company_Profile
    },
  },
};
</script>

<style lang="scss" scoped>
</style>