<template>
  <section class="section">
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="container">
        <div class="page-header">
          <!-- <h3>{{ company.companyName }}</h3> -->
          <h3>Company Information</h3>
          <button @click="handleSubmit(updateCompany)" class="button primary">
            Save
          </button>
        </div>
        <div class="box">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="logo">
                <div v-if="!imageData" @click="uploadFile()">
                  <img
                    src="../../../public/images/user-avatar/user-avatar-02.png"
                    alt=""
                  />
                </div>
                <!-- <div v-else :style="{ 'background-image': `url( ${imageData} )` }" @click="uploadFile()" alt="" ></div> -->
                <img v-else :src="imageData" @click="uploadFile()" alt="" />
                <i class="fas fa-camera"></i>

                <input
                  hidden="hidden"
                  name="image"
                  ref="fileinput"
                  type="file"
                  @input="onSelectFile"
                />
                <!-- <h3 v-if="!errorfileSize">Please upload your company logo</h3> -->
                <label v-if="!errorfileSize">{{ errorfileSize }}</label>
                <!-- <p>Supported file .jpg .png .gif | Maximum 5mb</p> -->

                <input
                  hidden="hidden"
                  name="image"
                  v-model="company.logo"
                  type="text"
                />
                <div></div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label for="" class="label">Company Name <span>*</span></label>
                <div class="control">
                  <ValidationProvider
                    name="Company Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="input"
                      v-model="company.companyName"
                      required
                      placeholder="Company name"
                    />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label for="" class="label">Industry <span>*</span></label>
                <div class="control">
                  <ValidationProvider
                    name="Select Industry"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <!-- <select v-model="data.industryId" class="input is-focused" >
                        <option
                            v-for="item in industries"
                            :key="item.index"
                            :value="item._id"
                            :selection="clickJob"
                      :updateText="selectedJob"
                        >{{ item.name }}</option>
                        </select> -->

                    <selection
                      v-model="form.Industry"
                      :items="Industry"
                      name="Industry"
                    />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="field">
                <label for="" class="label">Company Size <span>*</span></label>
                <div class="control">
                  <ValidationProvider
                    name="Salary"
                    rules="required|excluded"
                    v-slot="{ errors }"
                  >
                    <select
                      v-model="form.companySize"
                      class="input"
                      :class="{ error: errors[0] }"
                    >
                      <option
                        v-for="(sl, idx) in companySizes"
                        :key="idx"
                        :value="sl._id"
                      >
                        {{ sl.name }}
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="field">
                <label for="" class="label"
                  >Office No. <span class="optional">(Optional)</span></label
                >
                <div class="control">
                  <input
                    type="text"
                    v-model="company.phone"
                    class="input"
                    required
                    placeholder="021 / 030 xxxxxx"
                  />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label for="" class="label"
                  >Website <span class="optional">(Optional)</span></label
                >
                <div class="control">
                  <input
                    type="text"
                    v-model="company.website"
                    class="input"
                    required
                    placeholder="www.yourcompanywebsite.com"
                  />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label for="" class="label"
                  >Facebook Page <span class="optional">(Optional)</span></label
                >
                <div class="control">
                  <input
                    type="text"
                    v-model="company.facebook"
                    class="input"
                    required
                    placeholder="Place your facebook link here"
                  />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label for="" class="label">Country <span>*</span></label>
                <div class="control">
                  <ValidationProvider
                    name="Country"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select v-model="company.country" class="input is-focused">
                      <option
                        v-for="item1 in Country"
                        :key="item1.index"
                        :value="item1.name"
                      >
                        {{ item1.name }}
                      </option>
                    </select>
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div v-if="company.country == 'Laos'" class="column is-4">
              <div class="field">
                <label for="" class="label"
                  >Province / State <span>*</span></label
                >
                <div class="control">
                  <ValidationProvider
                    name="province"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      v-model="form.Province"
                      class="input is-focused"
                      @change="chooseProvince(form.Province)"
                    >
                      <option
                        v-for="(item2, index) in provinces"
                        :value="item2._id"
                        :key="index"
                      >
                        {{ item2.name }}
                      </option>
                    </select>
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div v-if="company.country == 'Laos'" class="column is-4">
              <div class="field">
                <label for="" class="label"
                  >District / City <span>*</span></label
                >
                <div class="control">
                  <ValidationProvider
                    name="District"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select v-model="form.districtId" class="input is-focused">
                      <option
                        v-for="item1 in districts"
                        :key="item1.index"
                        :value="item1._id"
                      >
                        {{ item1.name }}
                      </option>
                    </select>
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label for="" class="label"
                  >Address Village <span>*</span></label
                >
                <div class="control">
                  <ValidationProvider
                    name="Address Village"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="company.village"
                      class="input"
                      required
                      placeholder="Address Village"
                    />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="column is-12">
              <div class="field">
                <label for="" class="label"
                  >About Company <span class="optional">(Optional)</span></label
                >
                <div class="control">
                  <FroalaEditor v-model="company.aboutCompany" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-group">
          <button class="button info">Cancel</button>
          <button @click="handleSubmit(updateCompany)" class="button primary">
            Save
          </button>
        </div>
      </div>
    </ValidationObserver>
  </section>
</template>
<script>
import selection from "../selection";
import FroalaEditor from "../FroalaEditor";
import { GET_REUSE, GET_PROVINCES } from "@/graphql/Manage";
import apolloClient_Company from "@/services/apollo-client-company";
import { UPDATE_COMPANY } from "@/graphql/Company";
import axios from "axios";
export default {
  components: { selection, FroalaEditor: FroalaEditor },
  props: ["company"],
  data: () => ({
    Country: [],
    isLoading: true,
    Industry: [],
    provinces: [],
    me: "eee",
    toto: [],
    errorMessage: "",
    isShowSalary: true,
    jobposted: [],
    locationSelected: [],
    search: false,
    selectLocation: false,
    clickLanguage: false,
    selectedLanguage: [],
    clickJob: [],
    selectedJob: [],
    companies: [],
    jobs: [],
    locations: [],
    experiences: [],
    levels: [],
    languages: [],
    companySizes: [],
    tag: [],
    tagSelected: [],
    jobZone: [],
    zoneSelected: [],
    appliedEmail: [],
    nortificationEmail: [],
    notifyExpire: [""],
    period: null,
    recommend: false,
    imageData: null,
    errorfileSize: "",
    searchText: "",
    districts: [],
    // company:{
    //     logo:""
    // },
    form: {
      logo: "",
      file: "",
      companyId: "",
      title: "",
      job: [""],
      location: [""],
      experience: "",
      level: "",
      language: [""],
      salary: "",
      companySize: "",
      Industry: [],
      salaryid: true,
      description: "",
      tagIds: [],
      zone: [],
      coverletter: false,
      apply: false,
      uselink: false,
      link: "",
      applied: [""],
      nortification: [""],
      notifyExpire: [""],
      ispublished: false,
      postdate: null,
      closedate: "",
      expiredate: "",
      details: "",
      staffQTY: "",
      districts: [],
      Province: "",
      districtId: "",
      district: [],
    },
    pointInput: null,

    postDate: null,
  }),
  methods: {
    async updateCompany() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;
      if (this.company.country !== "Laos") {
        this.form.districtId = "";
        await apolloClient_Company.mutate({
          mutation: UPDATE_COMPANY,
          variables: {
            logo: this.company.logo,
            companyName: this.company.companyName,
            aboutCompany: this.company.aboutCompany,
            industryId: this.form.Industry,
            companySizeId: this.form.companySize,
            website: this.company.website,
            facebook: this.company.facebook,
            districtId: this.form.districtId,
            village: this.company.village,
            country: this.company.country,
            phone: this.company.phone,
          },
        });
      } else {
        await apolloClient_Company.mutate({
          mutation: UPDATE_COMPANY,
          variables: {
            logo: this.company.logo,
            companyName: this.company.companyName,
            aboutCompany: this.company.aboutCompany,
            industryId: this.form.Industry,
            companySizeId: this.form.companySize,
            website: this.company.website,
            facebook: this.company.facebook,
            districtId: this.form.districtId,
            village: this.company.village,
            country: this.company.country,
            phone: this.company.phone,
          },
        });
      }
      //window.location.reload();
    },
    chooseProvince(_id) {
      if (this.company.country !== "Laos") return;
      const province = this.provinces.find((i) => {
        return i._id.toString() === _id.toString();
      });
      this.districts = province.districts;
    },
    async fetchcompanySize() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          types: "CompanySize",
        },
      });
      this.companySizes = res.data.companyReuse;
      this.form.companySize = this.company.companySizeId._id;
    },
    async fetchCountry() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          types: "Country",
        },
      });
      this.Country = res.data.companyReuse;
    },

    async fetchProvince() {
      const res = await apolloClient_Company.query({
        query: GET_PROVINCES,
      });
      this.provinces = res.data.getProvinces;
    },

    async fetchIndustry() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          types: "Industry",
        },
      });
      this.Industry = res.data.companyReuse.map((i) => {
        return {
          _id: i._id,
          name: i.name,
        };
      });
    },

    chooseImage() {
      this.$refs.fileinput.click();
    },
    uploadFile() {
      this.$refs.fileinput.click();
    },
    async onSelectFile() {
      const input = this.$refs.fileinput;
      const files = input.files;

      const file = files[0];
      // console.log(size, "MB")

      const formData = new FormData();

      formData.append("file", file);
      // console.log(formData)
      if (file) {
        const filesize = file.size / 1024;
        const size = 5 * 1024;
        if (filesize < size) {
          axios.defaults.headers.common[
            "Authorization"
          ] = this.$store.getters.getCompanyToken;
          const res = await axios.post(`${this.$urlImageLogo}`, formData);
          this.imageData = `${this.$urlImageShow}` + res.data.fileKey;
          if (res.data.message) {
            this.errorfileSize = "The file must be .JPG, .PNG. JPEG, .SVG";
            this.company.logo = null;
            this.imageData = null;
          }
          if (res.data.fileKey) {
            this.errorfileSize = file.name;
            this.company.logo = res.data.fileKey;
          }
        } else {
          this.errorfileSize = "The file Size is too much";
          this.imageData = null;
        }
      }
    },
  },
  async created() {
    this.fetchcompanySize(),
      this.fetchIndustry(),
      this.fetchCountry(),
      await this.fetchProvince();
    if (this.company.logo) {
      this.imageData = this.$urlImageShow + this.company.logo;
    }
    this.form.Industry = this.company.industryId.map((i) => i._id);

    this.form.Province = this.company.districtId.provinceId._id;

    this.chooseProvince(this.form.Province);

    this.form.districtId = this.company.districtId._id;
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  position: static;

  i {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: var(--dark-grey-color);
  }

  // &::after{
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     border: 1px solid var(--border-color);
  //     left: -5px;
  //     top: -5px;
  //     width: 130px;
  //     height: 130px;
  //     border-radius: 5px;
  // }
}

.label {
  span {
    color: red;

    &.optional {
      color: var(--dark-grey-color);
      font-weight: normal;
    }
  }
}
</style>
