<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <div class="page-header-start">
          <h3>Email Setting</h3>
          <p>
            Note: The email(s) used below in each section can be duplicated or
            differentiated upon your preference.
          </p>
        </div>
        <div class="page-header-end">
          <button @click="addEmail()" class="button primary">Save</button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="box-header">
              <h3>CV Collecting Email(s)</h3>
              <p>
                The email(s) below will be used to receive and collect applicant
                CVs.
              </p>
            </div>
            <div class="field">
              <div
                class="control"
                v-for="(skill, index) in form.key.appliedEmails"
                :key="index"
              >
                <ValidationProvider
                  slim
                  name="email"
                  rules="email"
                  v-slot="{ errors }"
                >
                  <input
                    placeholder="Email"
                    type="text"
                    class="input"
                    v-model="form.key.appliedEmails[index]"
                    required
                  />
                  <span style="font-size: 12px; color: red" id="error">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
                <div class="option">
                  <i
                    class="fas fa-trash"
                    @click="form.key.appliedEmails.splice(index, 1)"
                  ></i>
                </div>
              </div>
              <button class="button primary" @click="AppliedEmails()">
                Add
              </button>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box">
            <div class="box-header">
              <h3>Job Post Notification Email(s)</h3>
              <p>
                The email(s) below will be used to get notification when any of
                your job post is published.
              </p>
            </div>
            <div class="field">
              <div
                class="control"
                v-for="(skill, index) in form.key.nortification"
                :key="index"
              >
                <ValidationProvider
                  slim
                  name="email"
                  rules="email"
                  style="width: 100%"
                  v-slot="{ errors }"
                >
                  <input
                    placeholder="Email"
                    type="text"
                    class="input"
                    v-model="form.key.nortification[index]"
                    required
                  />
                  <span style="font-size: 12px; color: red" id="error">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
                <div class="option">
                  <i
                    class="fas fa-trash"
                    @click="form.key.nortification.splice(index, 1)"
                  ></i>
                </div>
              </div>
              <button class="button primary" @click="Nortification()">
                Add
              </button>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box">
            <div class="box-header">
              <h3>Job Post Expiration Notification Email(s)</h3>
              <p>
                The email(s) below will be used to get notification of your job
                post expiration (24 hours in advance before your job post
                expired)
              </p>
            </div>
            <div class="field">
              <div
                class="control"
                v-for="(skill, index) in form.key.notifyExpire"
                :key="index"
              >
                <ValidationProvider name="email" v-slot="{ errors }">
                  <input
                    placeholder="Email"
                    type="text"
                    class="input"
                    v-model="form.key.notifyExpire[index]"
                    required
                  />
                  <span style="font-size: 12px; color: red" id="error">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
                <div class="option">
                  <i
                    class="fas fa-trash"
                    @click="form.key.notifyExpire.splice(index, 1)"
                  ></i>
                </div>
              </div>
              <button class="button primary" @click="notifyExpire()">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { STEING_EMAIL } from "@/graphql/Company";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  props: ["company"],

  data: () => ({
    form: {
      key: {
        nortification: [[]],
        appliedEmails: [[]],
        notifyExpire: [[]],
      },
    },
  }),
  methods: {
    Nortification() {
      this.form.key.nortification.push([]);
    },
    AppliedEmails() {
      this.form.key.appliedEmails.push([]);
    },
    notifyExpire() {
      this.form.key.notifyExpire.push([]);
    },
    async addEmail() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;
      await apolloClient_Company.mutate({
        mutation: STEING_EMAIL,
        variables: {
          nortificationEmails: this.form.key.nortification,
          appliedEmails: this.form.key.appliedEmails,
          notifyExpire: this.form.key.notifyExpire,
        },
      });
      (this.form.key.appliedEmails = this.company.appliedEmails),
        (this.form.key.nortification = this.company.nortificationEmails),
        (this.form.key.notifyExpire = this.company.notifyExpire);
    },
  },
  created() {
    (this.form.key.appliedEmails = this.company.appliedEmails),
      (this.form.key.nortification = this.company.nortificationEmails),
      (this.form.key.notifyExpire = this.company.notifyExpire);
  },
};
</script>

<style lang="scss" scoped>
.box {
  box-shadow: none;
  padding: 20px;
  .box-header {
    margin-bottom: 20px;

    h3 {
      font-size: var(--md-font);
      font-weight: 700;
      color: var(--text-color);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    p {
      font-size: var(--sm-font);
      line-height: 1.5;
    }
  }
}

.control {
  display: flex;
  // align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  span {
    flex-grow: 1;
  }
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .option {
    i {
      border-radius: 0 6px 6px 0;
      background-color: rgba(var(--alert-color-rgb), 0.15);
      color: var(--alert-color);
      height: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
